/*!
 * Remark (http://getbootstrapadmin.com/remark)
 * Copyright 2015 amazingsurge
 * Licensed under the Themeforest Standard Licenses
 */
!function (window, document, $) {
    'use strict';
    var $doc = $(document);

    $.site = $.site || {};

    $.extend($.site, {
        _queue: {prepare: [], run: [], complete: []},
        run: function () {
            var self = this;

            this.dequeue('prepare', function () {
                self.trigger('before.run', self);
            });

            this.dequeue('run', function () {
                self.dequeue('complete', function () {
                    self.trigger('after.run', self);
                })
            });
        },
        dequeue: function (name, done) {
            var self = this,
                queue = this.getQueue(name),
                fn = queue.shift(),
                next = function () {
                    self.dequeue(name, done)
                };

            fn ? fn.call(this, next) : $.isFunction(done) && done.call(this)
        },
        getQueue: function (name) {
            $.isArray(this._queue[name]) || (this._queue[name] = []);

            return this._queue[name];
        },
        extend: function (obj) {
            $.each(this._queue, function (name, queue) {
                if ($.isFunction(obj[name])) {
                    queue.push(obj[name]);
                    delete obj[name];
                }
            });

            $.extend(this, obj);

            return this;
        },
        trigger: function (name, data, $el) {
            if ('undefined' != typeof name) {
                if ('undefined' == typeof $el) {
                    $el = $doc;
                }

                $el.trigger(name + '.site', data);
            }
        },
        resize: function () {
            if ('function' === typeof document.createEvent) {
                var ev = document.createEvent('Event');

                ev.initEvent('resize', !0, !0);

                window.dispatchEvent(ev);
            } else {
                var element = document.documentElement,
                    event = document.createEventObject();

                element.fireEvent('onresize', event)
            }
        }
    });

    $.configs = $.configs || {};

    $.extend($.configs, {
        data: {}, get: function (name) {
            var callback = function (data, name) {
                return data[name]
            }, data = this.data, i;

            for (i = 0; i < arguments.length; i++) {
                name = arguments[i];
                data = callback(data, name);
            }

            return data
        }, set: function (name, value) {
            this.data[name] = value
        }, extend: function (name, options) {
            var value = this.get(name);

            return $.extend(!0, value, options)
        }
    });

    $.colors = function (name, level) {
        'primary' === name && (name = $.configs.get('site', 'primaryColor'));
        name || (name = 'red');

        if ('undefined' == typeof $.configs.colors) {
            return null;
        }

        if ('undefined' != typeof $.configs.colors[name]) {
            if (level && 'undefined' != typeof $.configs.colors[name][level]) {
                return $.configs.colors[name][level];
            }

            if ('undefined' == typeof level) {
                return $.configs.colors[name];
            }
        }

        return null;
    };

    $.components = $.components || {};

    $.extend($.components, {
        _components: {},
        register: function (name, obj) {
            this._components[name] = obj
        },
        init: function (name, context, args) {
            var self = this;

            if ('undefined' == typeof name) {
                $.each(this._components, function (name) {
                    self.init(name)
                });
            } else {
                context = context || document;
                args = args || [];

                var obj = this.get(name);

                if (obj) {
                    switch (obj.mode) {
                        case 'default':
                            return this._initDefault(name, context);
                        case 'init':
                            return this._initComponent(name, obj, context, args);
                        case 'api':
                            return this._initApi(name, obj, args);
                        default:
                            this._initApi(name, obj, context, args);

                            return this._initComponent(name, obj, context, args);
                    }
                }
            }
        },
        call: function (name, context) {
            var args = Array.prototype.slice.call(arguments, 2),
                obj = this.get(name);

            context = context || document;

            return this._initComponent(name, obj, context, args);
        },
        _initDefault: function (name, context) {
            if ($.fn[name]) {
                var defaults = this.getDefaults(name);

                $('[data-plugin=' + name + ']', context).each(function () {
                    var $this = $(this), options = $.extend(!0, {}, defaults, $this.data());
                    $this[name](options)
                })
            }
        },
        _initComponent: function (name, obj, context, args) {
            $.isFunction(obj.init) && obj.init.apply(obj, [context].concat(args))
        },
        _initApi: function (name, obj, args) {
            if ('undefined' == typeof obj.apiCalled && $.isFunction(obj.api)) {
                obj.api.apply(obj, args);
                obj.apiCalled = true;
            }
        },
        getDefaults: function (name) {
            var component = this.get(name);
            return component && 'undefined' != typeof component.defaults ? component.defaults : {}
        },
        get: function (name, property) {
            if ('undefined' != typeof this._components[name]) {
                if ('undefined' != typeof property) {
                    return this._components[name][property];
                } else {
                    return this._components[name];
                }
            }

            return false;
        }
    })
}(window, document, jQuery);