/*!
 * Remark (http://getbootstrapadmin.com/remark)
 * Copyright 2015 amazingsurge
 * Licensed under the Themeforest Standard Licenses
 */
!function (window, document, $) {
    $.components.register("material", {
        init: function (context) {
            $(".form-material", context).each(function () {
                var $this = $(this);
                if ($this.data("material") !== !0) {
                    var $control = $this.find(".form-control");
                    if ($control.attr("data-hint") && $control.after("<div class=hint>" + $control.attr("data-hint") + "</div>"), $this.hasClass("floating")) {
                        if ($control.hasClass("floating-label")) {
                            var placeholder = $control.attr("placeholder");
                            $control.attr("placeholder", null).removeClass("floating-label"), $control.after("<div class=floating-label>" + placeholder + "</div>")
                        }
                        (null === $control.val() || "undefined" == $control.val() || "" === $control.val()) && $control.addClass("empty")
                    }
                    $control.next().is("[type=file]") && $this.addClass("form-material-file"), $this.data("material", !0)
                }
            })
        }, api: function () {
            function _isChar(e) {
                return "undefined" == typeof e.which ? !0 : "number" == typeof e.which && e.which > 0 ? !e.ctrlKey && !e.metaKey && !e.altKey && 8 != e.which && 9 != e.which : !1
            }

            $(document).on("keydown.site.material paste.site.material", ".form-control", function (e) {
                _isChar(e) && $(this).removeClass("empty")
            }).on("keyup.site.material change.site.material", ".form-control", function () {
                var $this = $(this);
                "" === $this.val() && "undefined" != typeof $this[0].checkValidity && $this[0].checkValidity() ? $this.addClass("empty") : $this.removeClass("empty")
            }).on("focus", ".form-material-file", function () {
                $(this).find("input").addClass("focus")
            }).on("blur", ".form-material-file", function () {
                $(this).find("input").removeClass("focus")
            }).on("change", ".form-material-file [type=file]", function () {
                var $this = $(this), value = "";
                $.each($this[0].files, function (i, file) {
                    value += file.name + ", "
                }), value = value.substring(0, value.length - 2), value ? $this.prev().removeClass("empty") : $this.prev().addClass("empty"), $this.prev().val(value)
            })
        }
    });
}(window, document, jQuery);