/*!
 * Remark (http://getbootstrapadmin.com/remark)
 * Copyright 2015 amazingsurge
 * Licensed under the Themeforest Standard Licenses
 */
!function (window, document, $) {
    "use strict";

    var $body = $("body"),
        $html = $("html");

    $.site.menubar = {
        opened: true,
        folded: null,
        top: false,
        $instance: null,
        auto: true,
        init: function () {
            $html.removeClass("css-menubar").addClass("js-menubar");

            this.$instance = $(".site-menubar");

            if (0 !== this.$instance.length) {
                var self = this;

                if ($body.is(".site-menubar-top")) {
                    this.top = true;
                }

                if (($body.data("autoMenubar") === false || $body.is(".site-menubar-keep")) &&
                    ($body.hasClass("site-menubar-fold"))) {
                    this.auto = "fold";
                } else if ($body.hasClass("site-menubar-unfold")) {
                    this.auto = "unfold";
                }

                this.$instance.on("changed.site.menubar", function () {
                    self.update();
                });

                this.change();

                this.$instance.removeClass('out').addClass('in');
            }
        }, change: function () {
            var breakpoint = Breakpoints.current();

            this.reset();

            if (this.auto) {

                switch (this.auto) {
                    case "fold":
                        "xs" == breakpoint.name ? this.hide() : (this.restoreFoldState() || this.fold());

                        return;
                    case "unfold":
                        "xs" == breakpoint.name ? this.hide() : (this.restoreFoldState() || this.unfold());

                        return;
                }
            }

            if (breakpoint) {
                switch (breakpoint.name) {
                    case"lg":
                        this.restoreFoldState() || this.unfold();
                        break;
                    case"md":
                    case"sm":
                        this.restoreFoldState() || this.fold();
                        break;
                    case"xs":
                        this.hide()
                }
            }
        }, animate: function (doing, callback) {
            var self = this;

            $body.addClass("site-menubar-changing");
            doing.call(self);
            this.$instance.trigger("changing.site.menubar");

            setTimeout(function () {
                callback.call(self);
                $body.removeClass("site-menubar-changing");
                self.$instance.trigger("changed.site.menubar");
            }, 500)
        }, reset: function () {
            this.opened = null,
                this.folded = null,
                $body.removeClass("site-menubar-hide site-menubar-open site-menubar-fold site-menubar-unfold"),
                $html.removeClass("disable-scrolling")
        }, open: function () {
            this.opened !== true && this.animate(
                function () {
                    $body.removeClass("site-menubar-hide").addClass("site-menubar-open site-menubar-unfold"), this.opened = true, $html.addClass("disable-scrolling")
                },
                function () {
                    this.scrollable.enable()
                })
        }, hide: function () {
            this.hoverscroll.disable(),
            this.opened !== false && this.animate(
                function () {
                    $html.removeClass("disable-scrolling"), $body.removeClass("site-menubar-open").addClass("site-menubar-hide site-menubar-unfold"), this.opened = false
                },
                function () {
                    this.scrollable.enable()
                })
        }, unfold: function () {
            this.hoverscroll.disable();

            if (this.folded !== false) {
                this.animate(
                    function () {
                        $body.removeClass("site-menubar-fold").addClass("site-menubar-unfold"), this.folded = false
                    },
                    function () {
                        this.scrollable.enable();

                        if (null !== this.folded) {
                            $.site.resize();
                        }
                    });
            }

            this.saveFoldState('unfold');
        }, fold: function () {
            this.scrollable.disable(),
            this.folded !== true && this.animate(
                function () {
                    $body.removeClass("site-menubar-unfold").addClass("site-menubar-fold"), this.folded = true
                },
                function () {
                    this.hoverscroll.enable(), null !== this.folded && $.site.resize()
                })
            this.saveFoldState('fold');
        },
        saveFoldState: function (state) {
            if (window.sessionStorage) {
                window.sessionStorage.setItem('foldState', state);
            }
        },
        restoreFoldState: function (state) {
            if (window.sessionStorage) {
                var state = window.sessionStorage.getItem('foldState');

                if (typeof this[state] === 'function') {
                    this[state]();

                    return true;
                }
            }

            return false;
        }, toggle: function () {
            var breakpoint = Breakpoints.current(),
                folded = this.folded,
                opened = this.opened;

            switch (breakpoint.name) {
                case"lg":
                    null === folded || folded === false ? this.fold() : this.unfold();
                    break;
                case"md":
                case"sm":
                    null === folded || folded === true ? this.unfold() : this.fold();
                    break;
                case"xs":
                    null === opened || opened === false ? this.open() : this.hide()
            }
        }, update: function () {
            this.scrollable.update(), this.hoverscroll.update()
        }, scrollable: {
            api: null,
            "native": false,
            init: function () {
                return $body.is(".site-menubar-native") ? void(this["native"] = true) : void(this.api = $.site.menubar.$instance.children(".site-menubar-body").asScrollable({
                    namespace: "asScrollable",
                    skin: "scrollable-inverse",
                    direction: "vertical",
                    contentSelector: ">",
                    containerSelector: ">"
                }).data("asScrollable"))
            }, update: function () {
                this.api && this.api.update()
            }, enable: function () {
                this["native"] || (this.api || this.init(), this.api && this.api.enable())
            }, disable: function () {
                this.api && this.api.disable()
            }
        }, hoverscroll: {
            api: null,
            init: function () {
                this.api = $.site.menubar.$instance.children(".site-menubar-body").asHoverScroll({
                    namespace: "hoverscorll",
                    direction: "vertical",
                    list: ".site-menu",
                    item: "> li",
                    exception: ".site-menu-sub",
                    fixed: false,
                    boundary: 100,
                    onEnter: function () {
                    },
                    onLeave: function () {
                    }
                }).data("asHoverScroll")
            }, update: function () {
                this.api && this.api.update()
            }, enable: function () {
                this.api || this.init(), this.api && this.api.enable()
            }, disable: function () {
                this.api && this.api.disable()
            }
        }
    }
}(window, document, jQuery);