/*!
 * Remark (http://getbootstrapadmin.com/remark)
 * Copyright 2015 amazingsurge
 * Licensed under the Themeforest Standard Licenses
 */
!function (window, document, $) {
    "use strict";

    var $body = $(document.body);

    $.configs.set("site", {
        fontFamily: "Noto Sans, sans-serif",
        primaryColor: "blue"
    });

    window.Site = $.site.extend({
        run: function (next) {
            polyfillIEWidth();

            if ("undefined" != typeof $.site.menu) {
                $.site.menu.init()
            }

            if ("undefined" != typeof $.site.menubar) {

                $(".site-menubar").on("changing.site.menubar", function () {
                    $('[data-toggle="menubar"]').each(function () {
                        function toggle($el) {
                            $el.toggleClass("hided", !$.site.menubar.opened);
                            $el.toggleClass("unfolded", !$.site.menubar.folded);
                        }

                        var $this = $(this), $hamburger = $(this).find(".hamburger");
                        toggle($hamburger.length > 0 ? $hamburger : $this)
                    });

                    $.site.menu.refresh();
                });

                $(document).on("click", '[data-toggle="collapse"]', function (e) {
                    var $trigger = $(e.target);

                    if (!$trigger.is('[data-toggle="collapse"]')) {
                        $trigger = $trigger.parents('[data-toggle="collapse"]');
                    }

                    var href,
                        target = $trigger.attr("data-target") || (href = $trigger.attr("href")) && href.replace(/.*(?=#[^\s]+$)/, ""),
                        $target = $(target);

                    if ($target.hasClass("navbar-search-overlap")) {
                        $target.find("input").focus();
                        e.preventDefault();
                    } else if ("site-navbar-collapse" === $target.attr("id")) {
                        var isOpen = !$trigger.hasClass("collapsed");

                        $body.addClass("site-navbar-collapsing"), $body.toggleClass("site-navbar-collapse-show", isOpen), setTimeout(function () {
                            $body.removeClass("site-navbar-collapsing")
                        }, 350);

                        if (isOpen) {
                            $.site.menubar.scrollable.update();
                        }
                    }
                });

                $(document).on("click", '[data-toggle="menubar"]', function () {
                    $.site.menubar.toggle();
                });

                $.site.menubar.init();

                Breakpoints.on("change", function () {
                    $.site.menubar.change()
                })
            }

            if ("undefined" != typeof $.site.gridmenu) {
                $.site.gridmenu.init()
            }

            if ("undefined" != typeof $.site.sidebar) {
                $.site.sidebar.init()
            }

            $(document).tooltip({
                selector: "[data-tooltip=true]",
                container: "body"
            });

            $('[data-toggle="tooltip"]').tooltip();

            $('[data-toggle="popover"]').popover();

            $body.on("click", ".dropdown-menu-media", function (e) {
                e.stopPropagation()
            });

            $(document)
                .on("show.bs.dropdown", function (e) {
                    var $target = $(e.target), $trigger = e.relatedTarget ? $(e.relatedTarget) : $target.children('[data-toggle="dropdown"]'), animation = $trigger.data("animation");
                    if (animation) {
                        var $menu = $target.children(".dropdown-menu");
                        $menu.addClass("animation-" + animation), $menu.one("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend", function () {
                            $menu.removeClass("animation-" + animation)
                        })
                    }
                })
                .on("shown.bs.dropdown", function (e) {
                    var $target = $(e.target), $menu = $target.find(".dropdown-menu-media > .list-group");
                    if ($menu.length > 0) {
                        var api = $menu.data("asScrollable");
                        if (api)api.update(); else {
                            var defaults = $.components.getDefaults("scrollable");
                            $menu.asScrollable(defaults)
                        }
                    }
                });

            if ("undefined" != typeof screenfull) {
                $(document).on("click", '[data-toggle="fullscreen"]', function () {
                    return screenfull.enabled && screenfull.toggle(), !1
                });

                if (screenfull.enabled) {
                    document.addEventListener(screenfull.raw.fullscreenchange, function () {
                        $('[data-toggle="fullscreen"]').toggleClass("active", screenfull.isFullscreen)
                    })
                }
            }

            $.components.init();

            if ("undefined" != typeof $.animsition) {
                this.loadAnimate(function () {
                    $(".animsition").css({"animation-duration": "0s"});

                    next();
                })
            } else {
                next();
            }
        }
    });

    function polyfillIEWidth() {
        if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
            var msViewportStyle = document.createElement("style");
            msViewportStyle.appendChild(document.createTextNode("@-ms-viewport{width:auto!important}")), document.querySelector("head").appendChild(msViewportStyle)
        }
    }

    function loadAnimate(callback) {
        return $.components.call("animsition", document, callback)
    }

}(window, document, jQuery);